<template>
  <section class="coovallyHome_m">
    <div class="dialogcontainer">
      <div class="contactus">
        <el-dialog
          class="project_trash_dialog"
          :visible.sync="ContactUsVisible"
          width="288px"
          :close-on-click-modal="false"
          :show-close="false"
          style="margin-top: 15vh; padding: 0"
        >
          <div class="title">
            <span class="title_text">暂不支持手机端访问</span>
            <span class="title_text">请前往PC网页端进行注册体验</span>
          </div>
          <div class="dialog_button">
            <div class="close" @click="ContactUsVisible = false">我知道了</div>
          </div>
        </el-dialog>
      </div>
    </div>

    <!-- <div class="active" v-show="acticityShow">
      <img
        src="../../../assets/zh/images-mobile/shouye_huodong@2x.png"
        alt=""
        @click="RouteActicity()"
      />
      <span
        style="color: white"
        class="iconfont iconToast_closed close"
        @click="acticityShow = false"
      ></span>
    </div> -->

    
    <div class="main">
      <div class="banner">
        <div class="button">
          <el-button class="unchecked" style="width: 68px;" @click="RoutePush('/CooVallyHome')">{{
            $t("PC.Product.CooVallyButton.deaa52")
          }}</el-button>
          <el-button class="checked" >{{
            $t("PC.Product.CooVallyButton.2446d2")
          }}</el-button>
          <el-button class="unchecked" @click="RoutePush('/AIShopNew')">{{
            $t("PC.Product.CooVallyButton.fad28a")
          }}</el-button>
        </div>

        <div class="firstItem">
          <div class="title" v-show="$t('locale') == 'zh'">
            <span>{{ $t("PC.Product.CooVallyHome.f5c726") }}</span>
          </div>

          <div class="title_en" v-show="$t('locale') == 'en'">
            <span>{{ $t("PC.Product.CooVallyHome.f5c726") }}</span>
          </div>
          <div class="text">
            <p>
              {{ $t("PC.Product.CooVallyHome.modev1") }}
            </p>
            <p>
              {{ $t("PC.Product.CooVallyHome.modev2") }}
            </p>
          </div>
        </div>

        <div class="mainImg">
          <img :src="$t('locale') == 'zh' ? suanfas : suanfas_en" alt="" />
        </div>
      </div>

      <div class="apply">
        <el-button
          class="applyButton"
          @click="GoCooVally()"
          v-show="$t('locale') == 'zh'"
          >{{ $t("PC.Product.CooVallyButton.ed4a49") }}</el-button
        >

        <el-button
          class="applyButton_en"
          @click="GoCooVally()"
          v-show="$t('locale') == 'en'"
          >{{ $t("PC.Product.CooVallyButton.ed4a49") }}</el-button
        >
      </div>

      <div class="support">
        <div class="type">
          <div class="img">
            <img src="../../../assets/zh/images/icon1@2x.png" alt="" />
          </div>

          <div class="title" v-show="$t('locale') == 'zh'">
            <span>{{ $t("PC.Product.CooVallyHome.sptype") }}</span>
          </div>
          <div class="text" v-show="$t('locale') == 'zh'">
            <p>
              {{ $t("PC.Product.CooVallyHome.typetx") }}
            </p>
          </div>

          <div class="title_en" v-show="$t('locale') == 'en'">
            <span>{{ $t("PC.Product.CooVallyHome.sptype") }}</span>
          </div>
          <div class="text_en" v-show="$t('locale') == 'en'">
            <p>
              {{ $t("PC.Product.CooVallyHome.typetx") }}
            </p>
          </div>
        </div>

        <div class="function">
          <div class="img">
            <img src="../../../assets/zh/images/icon2@2x.png" alt="" />
          </div>

          <div class="title">
            <span>{{ $t("PC.Product.CooVallyHome.spfunc") }}</span>
          </div>
          <div class="text">
            <p>
              {{ $t("PC.Product.CooVallyHome.functx") }}
            </p>
          </div>
        </div>
      </div>

      <div class="model">
        <div class="model_title">
          <span>{{ $t("PC.Product.CooVallyHome.usemod") }}</span>
        </div>
        <div class="text">
          <p>
            {{ $t("PC.Product.CooVallyHome.seacmd") }}
          </p>
        </div>
        <div class="allmodel">
          <div class="caseContent" v-for="all in modellist" :key="all.title">
            <div class="title">
              <p>{{ all.title }}</p>
            </div>
            <div class="list">
              <div class="onetext" v-for="model in all.model" :key="model">
                <span class="icon"></span>
                <span class="msg">{{ model }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="moremodel">
        <div class="img">
          <img src="../../../assets/zh/images-mobile/maobi_img@2x.png" alt="" />
        </div>
        <div v-show="$t('locale') == 'zh'" class="text">
          <p>{{ $t("PC.Product.CooVallyHome.flowal") }}</p>
        </div>
        <div v-show="$t('locale') == 'en'" class="text_en">
          <p>{{ $t("PC.Product.CooVallyHome.flowen") }}</p>
        </div>
      </div>
    </div>

    <FooterB></FooterB>
  </section>
</template>

<script>
import FooterB from "../components/FooterB";
export default {
  components: {
    FooterB,
  },
  data() {
    return {
      acticityShow: true,
      suanfas: require("../../../assets/zh/images-mobile/home_img1.png"),
      suanfas_en: require("../../../assets/zh/images-mobile/home_img1_en.png"),
      ContactUsVisible: false,
      modellist: [
        {
          title: this.$t("PC.Product.CooVallyHome.mode01"),
          model: [
            "Faster R-CNN ",
            "RetinaNet",
            "SSD",
            "Cascade R-CNN",
            "FCOS ",
            "YoloV3 ",
            "FSAF",
            "CornerNet",
            "···",
          ],
        },
        {
          title: this.$t("PC.Product.CooVallyHome.mode02"),
          model: [
            "Mask R-CNN ",
            "Cascade Mask R-CNN",
            "Mask Scoring R-CNN",
            "SOLO",
            "SCNet",
            "PointRend",
            "···",
          ],
        },
        {
          title: this.$t("PC.Product.CooVallyHome.mode03"),
          model: ["ResNet18 ", "ResNet34", "ResNet50", "ResNet101", "···"],
        },
        {
          title: this.$t("PC.Product.CooVallyHome.mode04"),
          model: ["PSPNet", "FCN", "···"],
        },
        {
          title: this.$t("PC.Product.CooVallyHome.mode05"),
          model: [
            this.$t("PC.Product.CooVallyHome.smoot1"),
            this.$t("PC.Product.CooVallyHome.smoot2"),
            this.$t("PC.Product.CooVallyHome.smoot3"),
            this.$t("PC.Product.CooVallyHome.smoot4"),
            this.$t("PC.Product.CooVallyHome.smoot5"),
            this.$t("PC.Product.CooVallyHome.smoot6"),
            this.$t("PC.Product.CooVallyHome.smoot7"),
            this.$t("PC.Product.CooVallyHome.smoot8"),

            "···",
          ],
        },
        {
          title: this.$t("PC.Product.CooVallyHome.mode06"),
          model: [
            this.$t("PC.Product.CooVallyHome.enhan1"),
            this.$t("PC.Product.CooVallyHome.enhan2"),
            this.$t("PC.Product.CooVallyHome.enhan3"),
            this.$t("PC.Product.CooVallyHome.enhan4"),
            this.$t("PC.Product.CooVallyHome.enhan5"),
            this.$t("PC.Product.CooVallyHome.enhan6"),
            this.$t("PC.Product.CooVallyHome.enhan7"),

            "···",
          ],
        },
        {
          title: this.$t("PC.Product.CooVallyHome.mode07"),
          model: [
            this.$t("PC.Product.CooVallyHome.datas1"),
            this.$t("PC.Product.CooVallyHome.datas2"),
            this.$t("PC.Product.CooVallyHome.datas3"),
            this.$t("PC.Product.CooVallyHome.datas4"),
            this.$t("PC.Product.CooVallyHome.datas5"),
            "···",
          ],
        },
        {
          title: this.$t("PC.Product.CooVallyHome.mode08"),
          model: ["HSV", "YUV", "GARY", "RGB", "LUV", "HLS", "LAB", "···"],
        },
        {
          title: this.$t("PC.Product.CooVallyHome.mode09"),
          model: [
            "FastICA",
            "FeatureAgglomeration",
            "StandardScaler",
            "ZeroCount",
            "Normalizer",
            "MaxAbsScaler",
            "···",
          ],
        },
        {
          title: this.$t("PC.Product.CooVallyHome.mode10"),
          model: [
            "VarianceThreshold",
            "SelectFromModel",
            "RFE",
            "SelectPercentile",
            "SelectFwe",

            "···",
          ],
        },
        {
          title: this.$t("PC.Product.CooVallyHome.mode11"),
          model: [
            "Perceptron",
            "LogisticRegression",
            "LGBMClassifie",
            "AdaBoostClassifier",
            "GaussianNB",
            "BernoulliNB",
            "LinearSVC",
            "NearestCentroid",
            "···",
          ],
        },
        {
          title: this.$t("PC.Product.CooVallyHome.mode12"),
          model: [
            "AdaBoostRegressor",
            "RANSACRegressor",
            "LassoCV",
            "LinearSVR",
            "ExtraTreesRegressor",
            "ElasticNetCV",
            "BaggingRegressor",
            "DecisionTreeRegressor",

            "···",
          ],
        },
      ],
    };
  },

  methods: {
    RoutePush(url) {
      this.$router.push({
        path: url,
      });
    },

    RouteActicity() {
      let routeData = this.$router.resolve({ path: "/Acticity" });
      window.open(routeData.href, "_blank");
    },

    GoCooVally() {
      this.ContactUsVisible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.coovallyHome_m {
  margin-top: 48px;
  word-break: break-word !important;
  background: #fff;
  width: 100%;

  .dialogcontainer {
    .contactus {
      /deep/ .el-dialog__header {
        padding: 0;
      }

      /deep/ .el-dialog__body {
        padding: 0;
        margin-top: -10px;
      }

      .title {
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 30px;
        text-align: center;
        margin-top: 25px;
        padding-bottom: 10px;
      }
      /deep/ .dialog_button {
        padding-top: 14px;
        .close {
          width: 100%;
          height: 50px;
          padding-top: 13px;
          text-align: center;
          border-top: 1px solid #eaeaf0;
          // background: red;
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #2b2c3d;
          line-height: 22px;
        }
        .close:hover {
          color: #2b2c3dcc;
        }
      }
    }
  }

  .active {
    // display: flex;
    position: relative;
    z-index: 1;
    img {
      width: 100%;
      height: 100%;
    }

    .close {
      z-index: 99;
      position: absolute;
      right: 1vh;
      top: 8%;
      // background: red;
      // widows: 20px;
      height: 20px;
    }
  }

  .main {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: -1;
    .banner {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: -24px;
      width: 100%;

      background: linear-gradient(
        180deg,
        #eef2fe 0%,
        #f8f9ff 60%,
        rgba(238, 242, 254, 0) 100%
      );
      .button {
        display: flex;
        justify-content: space-between;
        width: 260px;
        padding-top: 36px;
        .checked {
          width: 68px;
          height: 30px;
          background-color: #4568ee;
          color: #fafcff;
          font-weight: 600;
          border: none;
          line-height: 30px;
          border-radius: 4px;
          padding: 0;
          font-size: 14px;
        }

        .unchecked {
          width: 83px;
          height: 30px;
          border: none;
          font-weight: 600;
          background-color: #4568ee1a;
          color: #4568ee;
          border-radius: 4px;
          padding: 0 0 0 0;
          font-size: 14px;
        }
      }
      .firstItem {
        margin-top: 14px;
        position: relative;
        text-align: center;

        .title {
          span {
            width: 305px;
            // height: 64px;
            font-size: 19px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #2d3242;
            line-height: 30px;
          }
        }

        .title_en {
          span {
            width: 305px;
            height: 64px;
            font-size: 19px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #2d3242;
            line-height: 30px;
          }
        }
        .text {
          width: 300px;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-bottom: 54px;
          p {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #2d3242;
            line-height: 20px;
          }
        }
      }

      .mainImg {
        position: relative;
        margin: 0 auto;
        width: 360px;
        height: 220px;
        margin-top: -50px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .apply {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 36px;
      margin-bottom: 36px;
      .applyButton {
        width: 80px;
        height: 29px;
        background: #4568ee;
        color: #fafcff;
        border-radius: 4px;
        padding: 0 0 0 0;
        font-size: 12px;
        line-height: 17px;
        font-weight: 600;
        border: none;
        line-height: 29px;
      }
      .applyButton_en {
        width: 108px;
        height: 29px;
        background: #4568ee;
        color: #fafcff;
        border-radius: 4px;
        padding: 0 0 0 0;
        font-size: 12px;
        line-height: 17px;

        font-weight: 600;
        border: none;
        line-height: 29px;
      }
    }

    .support {
      width: 90%;
      display: flex;
      flex-direction: column;
      align-items: center;
      // background-color: green;
      .type {
        width: 100%;
        // height: 165px;
        // background: linear-gradient(180deg, #F9FFFF 0%, #F0F4FE 100%, #F0F4FE 100%);
        background-image: url("../../../assets/zh/images/bg1@2x.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        border-radius: 4px;
        border: 1px solid rgba(151, 151, 151, 0);
        margin-bottom: 10px;
      }

      .function {
        width: 100%;
        // height: 165px;
        background-image: url("../../../assets/zh/images/bg1@2x.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        border-radius: 4px;
        border: 1px solid rgba(151, 151, 151, 0);
      }
      .img {
        margin-top: 17px;
        margin-left: 16px;
        img {
          width: 49px;
          height: 50px;
        }
      }
      .title {
        margin-top: 10px;
        margin-left: 16px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 36px;
      }
      .text {
        margin-top: 7px;
        margin-left: 16px;
        width: 320px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #636c78;
        padding-bottom: 20px;

        p {
          line-height: 16px;
        }
      }

      .title_en {
        margin-top: 10px;
        margin-left: 16px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 36px;
      }
      .text_en {
        margin-top: 7px;
        margin-left: 16px;
        width: 32 0px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #636c78;
        padding-bottom: 20px;
        p {
          line-height: 16px;
        }
      }
    }

    .model {
      width: 90%;
      display: flex;

      flex-direction: column;
      align-items: center;
      margin-top: 22px;

      .model_title {
        text-align: center;
        width: 300px;
        height: 22px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 600;
        color: #1f232e;
        line-height: 22px;
        margin-bottom: 6px;
      }
      .text {
        text-align: center;
        width: 305px;
        // height: 18px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #51565d;
        line-height: 22px;
      }

      .allmodel {
        margin-top: 16px;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .caseContent {
          width: 100%;
          margin-bottom: 24px;
          background: linear-gradient(
            180deg,
            #f9ffff 0%,
            #f0f4fe 100%,
            #f0f4fe 100%
          );
          border: 1px solid rgba(151, 151, 151, 0);
          .title {
            position: relative;
            top: 16px;
            left: 16px;
            width: 320px;
            margin-bottom: 10px;
            // height: 21px;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 600;
            color: #333333;
            p {
              line-height: 20px;
            }
          }
          .list {
            position: relative;
            top: 16px;
            left: 16px;
            width: 340px;
            padding-bottom: 16px;
            display: flex;
            flex-wrap: wrap;
            .onetext {
              // background-color: red;
              height: 24px;
              margin-bottom: 10px;
              display: flex;
              align-items: center;
              .icon {
                width: 7px;
                height: 7px;
                background: linear-gradient(180deg, #4ca9ff 0%, #3758ee 100%);
                box-shadow: 0px 2px 7px 0px rgba(104, 171, 247, 0.74);
                opacity: 0.8;
                border-radius: 4px;
              }
              .msg {
                margin-left: 7px;
                margin-right: 20px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #636c78;
                line-height: 19px;
              }
            }
          }
        }
      }
    }

    .moremodel {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 12px;
      margin-bottom: 15px;
      .img {
        position: relative;
        width: 335px;
        height: 40px;
        top: 0;
        left: 30px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .text {
        position: relative;
        top: -35px;
        width: 335px;
        height: 40px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #51565d;
        p {
          line-height: 18px;
        }
      }
      .text_en {
        position: relative;
        top: -50px;
        width: 335px;
        height: 40px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #51565d;
        p {
          line-height: 16px;
        }
      }
    }
  }
}

.img {
  // width:100%;
  img {
    width: 100%;
  }
}

ul {
  box-sizing: border-box;
  padding: 20px;

  & > p.big_title {
    color: #333;
    text-align: center;
    padding-bottom: 8px;
    font-size: 16px;
    line-height: 22px;
  }

  li {
    width: 335px;
    overflow: hidden;
    margin-bottom: 16px;

    img {
      width: 335px;
      height: 133px;
    }

    .content_box {
      padding: 12px 16px 18px;

      background-color: #f5f6f9;

      p.title {
        color: #333;
        font-size: 14px;
        line-height: 20px;
        padding-bottom: 8px;
        text-align: center;
        font-weight: 600;
      }

      .text {
        color: #666;
        font-size: 12px;
        line-height: 18px;
        width: 100%;
        font-size: 12px;
        line-height: 21px;
      }

      .more {
        display: inline-block;
        margin-top: 12px;
        width: 72px;
        height: 25px;
        border: 1px solid #666666;
        font-size: 10px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 24px;
        text-align: center;
      }
    }
  }
}
</style>
